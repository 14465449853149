$tint-color: #ad68d9;
$primary-color: #444;
$background-color: #000;

html {
  display: table;
  width: 100%;
}

body {
  display: table-cell;
  vertical-align: middle;
  
  text-align: center;
  
  font-size: 24px;
  font-weight: 600;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  
  color: $primary-color;
  background-color: $background-color;
  
  perspective: 1000;
}

html, body {
  width: 100%;
  height: 100%;
  
  overflow: hidden;
}

canvas {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

button {
  $gradient-from: $tint-color;
  $gradient-to: darken($tint-color, 2%);
  
  font: inherit;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  
  padding: 8px 16px;
  
  color: black;
  text-shadow: 0px 1px 2px rgba(white, 15%);
  
  background-color: $tint-color;
  background-image: linear-gradient($gradient-from 0%, $gradient-to 100%);
  box-shadow: inset 0px 0px 2px rgba(white, 15%); 
  
  border: none;
  border-radius: 8px;
  
  cursor: pointer;
  transition: all 0.15s ease-out;
  
  &:hover {
    transform: scale(1.05);
    background-image: linear-gradient(lighten($gradient-from, 10%) 0%, lighten($gradient-to, 10%) 100%);
  }
  
  &:active {
    transform: scale(.95);
    opacity: 0.5;
  }
}

audio {
  position: absolute;
  
  width: 0px;
  height: 0px;
  
  visibility: hidden;
}

@keyframes Cue-In {
  0% {
    opacity: 0;
    transform: scale(0.1) rotateX(45deg)
  }
  
  100% {
    transform: scale(1) rotateX(0)
  }
  
}

.cue-in {
  animation: Cue-In 2s ease-in-out;
}

